/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* General layout styling */
body {
  background-color: #f4f4f4;
  color: #333;
}

header {
  background-color: #333;
  color: white;
  padding: 1rem 0;
}

header nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
}

header nav ul li {
  margin: 0 1rem;
}

header nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

header nav ul li a:hover {
  text-decoration: underline;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  bottom: 0;
}

main {
  padding: 2rem;
  margin-bottom: 3rem; /* Extra space for fixed footer */
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

form {
  display: flex;
  flex-direction: column;
}

form label {
  margin-bottom: 0.5rem;
}

form input, form textarea {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form button {
  padding: 0.5rem;
  border: none;
  background-color: #333;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

form button:hover {
  background-color: #555;
}

.influencer-profile {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  margin: 1rem 0;
}

.influencer-profile img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.scaleElement {
  transform: scale(0.95) !important;
}