* {
    padding: 0;
    margin: 0;
}

.backgroundMainDiv {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: red;
}

/* .divBoderColor {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    border: 5px solid rgb(255, 0, 0);
    z-index: 1;
    background: red;
} */

.boxContainer {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
}

.hoverboxRow {
    display: flex;
    flex-direction: row;
}

.hoverBox {
    border: 1px solid rgb(26, 26, 26);
    background-color: black;
    transition: 0.5s ease-out;
    z-index: 10;
}

.hoverBox:hover {
    /* background-color: rgb(65, 65, 65); */
}